import { template as template_7f7534b721174304ba373b04f6b0eef0 } from "@ember/template-compiler";
const FKControlMenuContainer = template_7f7534b721174304ba373b04f6b0eef0(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
